<template>
  <div id="group-view" v-loading="loading" element-loading-text="拼命加载中">
    <div class="group-container">
      <div v-if="!loading" class="group-sale-sharing-p">
        <div v-if="groupInfo" class="class-head container">
          <h1>{{ groupInfo.title }}</h1>
          <p>{{ groupInfo.short_desp }}</p>
          <div class="price-section">
            <span class="text-danger" style="font-size: 16px; font-weight: 500"
              >团购价 <strong style="font-size: 12px">¥</strong></span
            >
            <span class="text-danger"
              ><strong>{{
                Number(groupInfo.price / 100).toFixed(2)
              }}</strong></span
            >
            <span class="text-origin" style="margin-left: 8px">
              <span
                v-if="groupInfo.material_goods_id > 0"
                class="material-price"
                >含{{
                  (groupInfo.material_goods_price / 100) | handleCountPrice
                }}元教材费</span
              >
              <span style="font-size: 12px"
                >原价 ¥{{
                  Number(groupInfo.original_price / 100).toFixed(2)
                }}</span
              >
            </span>
          </div>
        </div>
        <div style="height: 8px; background: rgba(51, 51, 51, 0.03)"></div>
        <div class="container">
          <slot v-if="btnStatus.pintuanIng"></slot>
          <slot v-else-if="btnStatus.pintuanSuccess">
            <div class="biaoqian pintuan-success">
              <i class="fa-zhixin fa-check-circle"></i><span>拼团成功</span>
            </div>
          </slot>
          <slot v-else>
            <div class="biaoqian pintuan-failed">
              <i class="fa fa-icon_home_clear"></i>
              <span>已超时，拼团失败</span>
            </div>
          </slot>
        </div>

        <div v-show="btnStatus" class="members container">
          <slot v-if="btnStatus.pintuanIng">
            <div
              style="margin-bottom: 10px; font-size: 18px; position: relative"
            >
              <div class="line-split"></div>
              <span class="count-down" v-html="timeHtml"></span>
            </div>
            <div class="contaienr" style="padding: 10px; line-height: 1">
              仅差<span class="needed-cantuan-amounts">{{
                getPintuData.neededPeople
              }}</span>
              人拼团成功
            </div>
          </slot>
          <div v-if="btnStatus" class="actions">
            <a
              v-if="btnStatus.href"
              class="xiazai-app action"
              :href="btnStatus.href"
              >{{ btnStatus.btnText }}</a
            >
            <div
              v-else
              @click="handleBtn"
              :class="'fenxiang action ' + btnStatus.btnClass"
            >
              {{ btnStatus.btnText }}
            </div>
          </div>
          <div class="members-container">
            <div class="flex-members">
              <slot v-for="(item, index) in getPintuData.members">
                <span v-if="item && item.isLeader" v-bind:key="index">
                  <img :src="item.avatar" alt="团长" />
                  <span class="badge btn-success">团长</span>
                  <span v-if="item.isMe" class="is-me"></span>
                </span>
                <span v-else-if="item && item.user_id" v-bind:key="index">
                  <img :src="item.avatar" alt="" />
                  <span v-if="!item.isPaid" class="badge btn-default"
                    >未支付</span
                  >
                  <span v-if="item.isMe" class="is-me"></span>
                </span>
                <span v-else class="weizhi" v-bind:key="index">
                  <span style="font-size: 20px"></span>
                </span>
              </slot>
            </div>
          </div>
          <slot
            v-if="
              btnStatus.pintuanSuccess &&
              btnStatus.isCantuan &&
              getPintuData.qq_group
            "
          >
            <div class="qq_group" style="margin-top: 20px">
              <strong>加入专属QQ群</strong>
            </div>
            <p class="qq_group">
              扫码或搜索群号({{ getPintuData.qq_group }})加入
              <button
                class="copy btn-success-ghost"
                data-toggle="tooltip"
                data-placement="auto"
                title="复制成功"
              >
                复制群号
              </button>
            </p>
            <div class="clearfix qq_group" style="font-size: 12px">
              <img
                class="pull-left"
                :src="getPintuData.qr_code"
                width="88"
                height="88"
                alt="专属QQ群"
              />
              <div class="pull-left right-words">
                <div>学习资料->独家内部资料下载</div>
                <div>老师互动->疑问随时得到解答</div>
                <div>同学交流->相互辅导相互监督</div>
                <div>监督刷题->共同刷题分享收获</div>
              </div>
            </div>
          </slot>
        </div>
        <div class="container">
          <div class="dashed-h"></div>
        </div>
        <div class="container pintuan-shuoming">
          <span>
            <label class="groupicons-sprite groupicons-haoyou"></label>
            <span>和好友拼团</span>
          </span>
          <span class="dot"></span>
          <span>
            <label class="groupicons-sprite groupicons-guize"></label>
            <span>人满成团 开始学习 / 人不满 原路退款</span>
          </span>
        </div>
        <div style="height: 8px; background: rgba(51, 51, 51, 0.03)"></div>
        <div class="class-detail container" v-html="groupInfo.desp"></div>
        <div v-if="btnStatus" class="fixed-bar container">
          <a
            v-if="btnStatus.href"
            class="xiazai-app action"
            :href="btnStatus.href"
            >{{ btnStatus.btnText }}</a
          >
          <div
            v-else
            @click="handleBtn"
            :class="'fenxiang action ' + btnStatus.btnClass"
          >
            {{ btnStatus.btnText }}
          </div>
        </div>
      </div>
      <ShareTip ref="ShareTip" />
    </div>
    <div id="confirm-order-container"></div>
  </div>
</template>

<script>
import api from "@/plugins/api";
import $ from "jquery";
import { isMobile, isWeChat } from "@/utils/utils";
import { STATES } from "@/utils/constants";
import { mapGetters } from "vuex";
import ShareTip from "@/components/ShareTip";

export default {
  name: "GroupActivity",
  data() {
    return {
      isPaided: false,
      loading: true,
      groupNo: "",
      groupInfo: {},
      classId: "",
      qrcodeImg: "",
      // btnStatus: '',
      timestamp: "",
      getPintuData: "",
      timeHtml: "",
      shareData: {
        ren: "",
        originPrice: "",
        groupPrice: "",
      },
      type: 0,
    };
  },
  components: {
    ShareTip,
  },
  filters: {
    handleCountPrice(e) {
      let price = e;
      if (String(price).indexOf(".") > -1) {
        price = price.toFixed(2);
      }
      return price;
    },
  },
  mounted() {
    this.groupNo = this.$route.params.groupNo;
    if (this.groupNo) {
      this.init();
    } else {
      this.$router.push({ path: "/404" });
    }
    const hashSearch = window.location.href.split("?")[1];
    this.isPaided = hashSearch && hashSearch.includes("isPaided=true");
    setTimeout(() => {
      this.scrollPage();
    }, 1000);
    $("#footer").hide();
  },
  methods: {
    init() {
      api.getGroupInfoByGroupId({ group_id: this.groupNo }).then(
        (res) => {
          if (res.data.code === 1) {
            this.classId = res.data.data.goods_info.class_id;
            if (Number(res.data.data.goods_info.online) === 0) {
              this.$msgDialog("没有此课程", () => {
                this.$router.push({ path: "/" });
              });
              return false;
            }
            this.groupInfo = Object.assign(
              {},
              res.data.data.group_info,
              res.data.data.goods_info
            );
            this.groupInfo.original_price = res.data.data.goods_info.price;
            this.groupInfo.price = res.data.data.group_info.price;
            this.getPintuData = this.getPintuanData();
            if (this.btnStatus.pintuanIng) {
              this.startTimer();
            }
            this.initShareInfo();
          } else {
            this.$message.warning(res.data.msg);
          }
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    },
    initShareInfo() {
      if (this.isMobile) {
        this.shareData = {
          ren: this.getPintuData.neededPeople || 0,
          originPrice: this.getOriginPrice(),
          groupPrice: this.getPrice(),
          title: this.groupInfo.title,
        };
        this.$refs.ShareTip.initShareInfo(this.shareData);
      }
    },
    getPrice: function () {
      return (this.groupInfo.price / 100).toFixed(2);
    },
    getOriginPrice: function () {
      return (this.groupInfo.original_price / 100).toFixed(2);
    },
    handleBtn() {
      this.groupInfo.paramsPrice = this.groupInfo.price;
      if (this.btnStatus.btnClass === "share") {
        this.$refs.ShareTip.share(this.shareData);
      } else if (this.btnStatus.btnClass === "cantuan") {
        this.goConfirmOrder(this.groupInfo.group_id);
      } else if (this.btnStatus.btnClass === "reopen") {
        this.goConfirmOrder();
      } else if (this.btnStatus.btnClass === "login") {
        this.$showLoginDialog();
        return false;
      }
    },
    goConfirmOrder(groupId) {
      document.querySelector(".group-container").style.display = "none";
      let groupInfo = JSON.parse(JSON.stringify(this.groupInfo));
      groupInfo.subclass = groupInfo.subs_info;
      let params;
      if (groupId) {
        params = {
          activity_id: Number(groupId),
          activity_type: 1,
        };
      } else {
        params = {
          group_num: Number(this.groupInfo.group_num),
          activity_type: 1,
        };
      }
      this.$confirmOrder([groupInfo], "confirm-order-container", params, 4);
    },
    scrollPage() {
      const $actionBar = $(".fixed-bar");
      const offsetHeight =
        $(".navbar-fixed-top").height() - $(".actions .action").height();
      if ($actionBar.length === 0) {
        return;
      }
      $(window)
        .off(".groupActivity")
        .on("scroll.groupActivity", () => {
          const $target = $(".actions .action");
          if ($target.length === 0) {
            return;
          }
          const scrollTop = $(window).scrollTop();
          const targetOffsetTop = $target.offset().top;
          if (scrollTop >= targetOffsetTop - offsetHeight) {
            $actionBar.slideDown();
          } else {
            $actionBar.slideUp();
          }
        });
    },
    startTimer() {
      const payEndTime = Number(this.getPintuData.pay_end / 1000);
      this.timestamp = Math.floor(payEndTime) - Math.floor($.now() / 1000);
      this.timerHtml(this.timestamp);
      this.timer = window.setInterval(() => {
        if (Number(this.timestamp) < 1) {
          this.groupInfo.state = 3;
          clearInterval(this.timer);
        } else {
          this.timestamp -= 1;
          this.timerHtml(this.timestamp);
        }
      }, 1000);
    },
    timerHtml(time) {
      this.timeHtml = "";
      time = time < 0 ? 0 : time;
      const dd = Math.floor(time / (24 * 60 * 60));
      const hh = Math.floor(time / (60 * 60)) % 60;
      const mm = Math.floor(time / 60) % 60;
      const ss = time % 60;
      if (dd > 0) {
        this.timeHtml +=
          '<span class="days">' +
          dd +
          '</span><span class="days-text">天 </span>';
      }
      this.timeHtml +=
        '<span class="hours">' +
        (String(hh).length > 1 ? hh : "0" + hh) +
        "</span>:";
      this.timeHtml +=
        '<span class="minutes">' +
        (String(mm).length > 1 ? mm : "0" + mm) +
        "</span>:";
      this.timeHtml +=
        '<span class="seconds">' +
        (String(ss).length > 1 ? ss : "0" + ss) +
        "</span>&nbsp;&nbsp;&nbsp;&nbsp;后结束";
    },
    getCantuanInfo: function () {
      if (!this.isAuthenticated) {
        return { includeMe: false };
      }
      const userId = Number(this.user.user_id);
      const orderInfo =
        this.groupInfo.member_info &&
        this.groupInfo.member_info.filter(
          (item) => Number(item.user_id) === userId
        )[0];
      if (!orderInfo) {
        return { includeMe: false };
      }
      return {
        paid: orderInfo.state === 2,
        includeMe: true,
      };
    },
    getHuodongClosed: function () {
      if (Number(this.groupInfo.group_set_end_at) === 0) {
        return false;
      }
      return Number(this.groupInfo.group_set_end_at) < +new Date() / 1000;
    },
    getEnded: function () {
      return Number(this.groupInfo.is_drop) === 1;
    },
    getPintuanData: function () {
      const _this = this;
      const kaituanPeople = this.groupInfo.group_num;
      const members = this.groupInfo.member_info || [];
      let joinedPeople = 0;
      const membersList = members.map((item) => {
        const isPaid = item.state === 2;
        const isMe = item.user_id && item.user_id === this.user.user_id;
        if (isPaid) {
          joinedPeople += 1;
        }
        _this.isLeader = item.is_leader === 1 && isMe;
        return {
          isLeader: item.is_leader === 1,
          avatar: item.avatar || "/img/avatar/avatar_user.png",
          user_id: item.user_id,
          isPaid: isPaid,
          isMe,
          is_leader: item.is_leader,
        };
      });
      membersList.sort((a, b) => {
        return a.is_leader > b.is_leader;
      });
      membersList.length = kaituanPeople;
      return {
        pay_end: this.getPayEndAt(),
        kaituanPeople: kaituanPeople,
        joinedPeople: joinedPeople,
        neededPeople: kaituanPeople - joinedPeople,
        members: membersList,
        qq_group: this.groupInfo.qq_group,
        qr_code: this.groupInfo.qr_code,
      };
    },
    getPayEndAt: function () {
      const durations = this.groupInfo.duration;
      return (this.groupInfo.create_at + durations * 60 * 60) * 1000;
    },
    getMyCourseDetail() {
      return `${process.env.VUE_APP_DOMAIN}/my-course/course-detail/${this.groupInfo.class_id}?course_type=0`;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user"]),
    isMobile() {
      return isMobile();
    },
    isWechat() {
      return isWeChat();
    },
    isIOS() {
      const userAgentInfo = navigator.userAgent;
      return /iphone|ipad|ipod/.test(userAgentInfo.toLowerCase());
    },
    isMqq() {
      const userAgentInfo = navigator.userAgent;
      return userAgentInfo.toLowerCase().indexOf(" qq/") !== -1;
    },
    isAndroid() {
      const userAgentInfo = navigator.userAgent;
      return /android|linux/.test(userAgentInfo.toLowerCase());
    },
    btnStatus() {
      const state = this.groupInfo.state;
      const cantuanInfo = this.getCantuanInfo();
      const status = {
        huodongClosed: this.getHuodongClosed(),
        ended: this.getEnded(),
        actionStatus: 1,
        href: "",
        isCantuan: cantuanInfo.includeMe,
        pintuanIng: state === STATES.KAITUAN,
        pintuanSuccess: state === STATES.PINTUAN_SUCCESS,
        btnClass: "",
      };
      if (state === STATES.KAITUAN) {
        if (!this.isAuthenticated) {
          status.btnText = "登录后参团";
          status.btnClass = "login";
        } else {
          status.btnText = cantuanInfo.includeMe ? "邀请好友拼团" : "立即参团";
          status.btnClass = cantuanInfo.includeMe ? "share" : "cantuan";
        }
      } else if (state === STATES.PINTUAN_SUCCESS) {
        if (!this.isAuthenticated) {
          status.btnText = "登录后查看";
          status.btnClass = "login";
        } else if (cantuanInfo.includeMe) {
          status.btnText = "观看课程";
          status.href = this.getMyCourseDetail();
        } else {
          status.btnText =
            status.huodongClosed || status.ended
              ? "活动结束，查看课程"
              : "自己开团";
          if (status.huodongClosed || status.ended) {
            status.href = this.getMyCourseDetail();
          } else {
            status.btnClass = "reopen";
          }
        }
      } else {
        if (status.huodongClosed || status.ended) {
          status.btnText = "活动结束，查看课程";
          status.href = this.getMyCourseDetail();
        } else if (this.isAuthenticated) {
          status.btnText = "重新开团";
          status.btnClass = "reopen";
        } else {
          status.btnText = "登录后开团";
          status.btnClass = "login";
        }
      }
      // if (status.btnText === '观看课程') {
      //   if (this.isMobile && this.isAndroid) {
      //     status.href = 'https://sj.qq.com/myapp/detail.htm?apkName=com.eduzhixin.app'
      //     status.btnText = '下载APP观看课程'
      //   }
      //   if (this.isMobile && this.isIOS) {
      //     status.href = 'https://itunes.apple.com/cn/app/zhi-xin-zai-xian/id1157874179?mt=8'
      //     status.btnText = '下载APP观看课程'
      //   }
      // }
      return status;
    },
    getCantanStatus() {
      if (this.groupInfo.state === STATES.KAITUAN) {
        return "待成团";
      } else if (this.groupInfo.state === STATES.PINTUAN_SUCCESS) {
        return "已成团";
      } else {
        return "已过期";
      }
    },
    getIndentify() {
      if (!this.isAuthenticated) {
        return "未登录";
      } else if (!this.btnStatus.isCantuan) {
        return "未参团";
      } else {
        return this.getPintuData.isLeader ? "团长" : "团成员";
      }
    },
  },
  beforeDestroy() {
    $("#footer").show();
    clearInterval(this.timer);
    $(window).off(".groupActivity");
  },
};
</script>
<style scoped lang="scss">
$primary: #333;
$live-primary: #979dac;
$select: $purple-color;
$selectedBtn: #6f7480;
$boxshadow: 0 0 20px 4px rgba(169, 175, 183, 0.4);
$success: $purple-color;
$vice: rgba(44, 45, 46, 0.5);
$subject: #333;
$desp: rgba(44, 45, 46, 0.5);
$nav: #7b808a;
$bottomBar: #fff;
$border: #e8eaed;
$group-color: #ef4d3c;
#group-view {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  padding-top: 24px;
  font-size: 14px;
  .container:before,
  .container:after {
    content: " ";
  }

  .group-sale-sharing-p {
    max-width: 520px;
    padding-bottom: 50px;
    margin: 0 auto;
    > .container {
      max-width: 520px;
    }

    .shared-order-page {
      color: $chiral-color;
      font-size: 10px;
      background: #fff;
    }

    .pintuan-shuoming {
      font-size: 13px;
      font-weight: 400;
      color: rgba($chiral-color, 0.8);
      padding: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #fff;

      label,
      label + span {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
      }

      label {
        margin-right: 4px;
      }

      .groupicons-sprite {
        width: 20px;
        height: 19px;
        background-size: 100%;

        &.groupicons-haoyou {
          background-image: url("../../assets/img/haoyou.png");
          background-repeat: no-repeat;
        }

        &.groupicons-guize {
          background-image: url("../../assets/img/guize.png");
          background-repeat: no-repeat;
        }
      }

      .dot {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: rgba(51, 51, 51, 0.1);
      }
    }

    .class-detail {
      font-size: 14px;
      color: $chiral-color;
      min-height: 198px;
      padding: 30px 15px;
      background: rgba(255, 255, 255, 1);
      ::v-deep img {
        max-width: 100% !important;
      }
    }

    .class-head {
      position: relative;
      background: rgba(255, 255, 255, 1);
      padding: 0px 15px;
      p {
        color: rgba(51, 51, 51, 0.6);
      }

      .price-section {
        font-size: 18px;
        margin-top: 20px;
        margin-bottom: 20px;
        line-height: 1;

        .text-origin {
          color: rgba(51, 51, 51, 0.4);
          font-weight: 400;
          .material-price {
            border-radius: 2px;
            padding: 2px;
            font-size: 12px;
            background: rgba(198, 205, 218, 0.3);
            color: rgba(51, 51, 51, 0.5);
            margin-right: 8px;
          }
        }

        .text-danger {
          color: $group-color;
        }
      }
    }

    .biaoqian {
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 18px;
      text-align: center;
      margin: 20px 0 10px;

      .fa {
        margin-right: 8px;
      }

      .fa,
      .fa + span {
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
      }

      .fa-icon_home_clear {
        color: #dedede;
      }
    }

    .biaoqian.pintuan-success {
      color: $purple-color;
    }

    .biaoqian.pintuan-failed {
      color: $chiral-color;
    }

    .class-head h1 {
      font-weight: 500;
      font-size: 18px;
    }

    .members {
      padding: 10px 12px;
      background: rgba(255, 255, 255, 1);
      position: relative;
      text-align: center;
    }

    .dashed-h {
      color: rgba(51, 51, 51, 0.06);
      border-bottom-width: 1px;
      border-bottom-style: dashed;
    }
    .class-detail {
      margin-top: 4px;
      padding: 36px 12px;
      background: rgba(255, 255, 255, 1);
      font-size: 13px;
      font-weight: 400;
      color: rgba(51, 51, 51, 0.5);
      ::v-deep img {
        width: 100% !important;
      }
      hr {
        height: 0;
        border: 0;
        margin-top: 20px;
        margin-bottom: 20px;
        box-sizing: content-box;
        border-top: 1px solid #eee;
      }
    }

    .gray-p {
      background-color: rgb(223, 223, 223);
      border-radius: 3px;
      height: 10px;
    }

    .flex-members {
      text-align: center;

      > span {
        width: 39px;
        height: 39px;
        position: relative;
        margin-bottom: 12px;
        line-height: 39px;
        display: inline-block;
        font-size: 0;
        margin-right: 16px;
        border-radius: 50%;
        background-image: url("../../assets/img/touxiang.png");
        background-size: 40px;
        background-position: center center;
        background-repeat: no-repeat;
        text-align: center;
        vertical-align: bottom;
      }
    }

    .flex-members > span:last-child {
      margin-right: 0;
    }

    .flex-members img {
      width: 39px;
      height: 39px;
      border-radius: 50%;
    }

    .flex-members .is-me {
      height: 14px;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      font-weight: 500;
      color: #fff;
      line-height: 14px;
      font-size: 10px;
    }

    .flex-members .is-me::before {
      content: "我";
      color: #fff;
      font-size: 10px;
      line-height: 5.4;
      height: 39px;
      background: #3336;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      border-radius: 0 0 39px 39px;
      text-align: center;
    }

    .badge {
      display: inline-block;
      min-width: 10px;
      padding: 3px 7px;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      line-height: 1;
      vertical-align: middle;
      white-space: nowrap;
      text-align: center;
      background-color: #c8c9cc;
      border-radius: 10px;
    }

    .flex-members .badge {
      position: absolute;
      left: 14px;
      font-weight: 500;
      border: none;

      &.btn-success {
        transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
        background: linear-gradient(
          308deg,
          $purple-color 0%,
          $purple-color 100%
        );
      }

      &.btn-default {
        padding-left: 3px;
        padding-right: 3px;
        background: rgba(205, 211, 223, 1);
        font-size: 11px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 12px;
      }
    }

    .needed-cantuan-amounts {
      color: #ef4d3c;
      font-size: 24px;
    }

    .dao-ji-shi {
      width: 151px;
      text-align: left;
      margin-top: 6px;
    }

    /*group-action*/
    .fixed-bar {
      position: fixed;
      bottom: 0;
      width: 100%;
      color: #2f3031;
      text-align: center;
      background: #fff;
      display: none;
      transform: translateX(-50%);
      left: 50%;
      z-index: 1029;
      border-top: 1px solid #eef2f7;
    }

    .actions {
      text-align: center;
      background: #fff;
      margin-bottom: 20px;
    }

    .action {
      position: relative;
      top: 0;
      height: 42px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 1);
      line-height: 42px;
      width: 100%;
      border-radius: 21px;
      display: block;
      margin: 10px auto;
      cursor: pointer;

      &.fenxiang {
        background: linear-gradient(
          90deg,
          rgba(248, 90, 74, 1) 0%,
          rgba(255, 118, 104, 1) 100%
        );

        &.disabled {
          background: rgba(51, 51, 51, 0.16);
        }
      }

      &.xiazai-app {
        background: linear-gradient(
          308deg,
          $purple-color 0%,
          $purple-color 100%
        );
      }

      &.share {
        text-align: center;
        padding: 0;
      }
    }

    .pt-success-title {
      color: $purple-color;
      font-size: 13px;

      .fa {
        font-size: 19.5px;
        margin-right: 6px;
      }
    }

    .shixiao-fa,
    .shixiao-fa + span {
      vertical-align: middle;
      display: inline-block;
    }

    .qq_group {
      text-align: left;
      color: $primary;
      font-size: 14px;

      strong {
        font-weight: 500;
      }

      button {
        border-radius: 2px;
        font-weight: 400;
        letter-spacing: 1px;
        outline: none;
        border-style: solid;

        &:active,
        &:hover,
        &:focus {
          outline: none;
          background-color: #fff;
          color: $purple-color;
        }
      }

      .right-words {
        font-size: 12px;
        padding-left: 24px;

        > div {
          line-height: 22px;
        }
      }
    }

    .line-split {
      height: 1px;
      background: rgba(51, 51, 51, 0.06);
      border-radius: 2px;
      position: absolute;
      width: 100%;
      top: 15px;
    }

    .count-down {
      background-color: #fff;
      font-size: 13px;
      padding: 10px;
      z-index: 2;
      position: relative;

      span {
        margin: 0 4px;
        width: 20px;
        height: 20px;
        background: rgba(51, 51, 51, 0.1);
        border-radius: 4px;
        display: inline-block;
        vertical-align: middle;
        line-height: 20px;
      }

      .days-text {
        font-size: 8px;
        background: transparent;
      }
    }
  }

  .groupicons-guize {
    background-position: 0 0;
  }

  .Mobile_P .group-sale-sharing-p {
    .members-container {
      overflow-x: scroll;
    }

    .flex-members {
      display: flex;
      flex-wrap: wrap;
      width: max-content;
      margin: auto;
    }
  }
}
</style>
