<template>
  <div class="group-share">
    <!-- 微信，qq,webview分享弹窗 -->
    <div class="share-tip-inWechat" v-if="shareTips">
      <div class="arrow-topRight clearfix">
        <img src="@/assets/img/arrow@3x.png" alt="" />
      </div>
      <div class="tip-text">点击右上角菜单分享此页面</div>
      <div class="i-know-it" @click="shareTips = false">
        <img src="@/assets/img/iknowit@3x.png" alt="" />
      </div>
    </div>
    <!-- 分享二维码弹窗 -->
    <el-dialog
      custom-class="dialog-420"
      :visible.sync="qrcodeVisible"
      :close-on-press-escape="false"
      title=""
      :close-on-click-modal="false"
      center
    >
      <p class="text-center">
        <img :src="qrcodeImg" alt="分享二维码" />
      </p>
      <p class="text-center">请使用手机微信或QQ扫码分享哦～</p>
    </el-dialog>
  </div>
</template>
<script>
import qrcode from "qrcode";
import { isMobile, isWeChat, clipboard, pageShare, isMqq } from "@/utils/utils";
import $ from "jquery";
import wx from "weixin-js-sdk";
import qs from "qs";

export default {
  name: "ShareTip",
  data() {
    return {
      qrcodeImg: "",
      shareData: {},
      shareTips: false,
      qrcodeVisible: false,
      flag: false,
    };
  },
  methods: {
    initShareInfo(shareData) {
      this.shareData = shareData;
      const title = `【仅剩${this.shareData.ren}个名额】我${this.shareData.groupPrice}元拼了课程《${this.shareData.title}》`;
      const desc = `限时活动，${this.shareData.groupPrice}元购买原价${this.shareData.originPrice}元课程《${this.shareData.title}》，手慢无！`;
      const $metaDesc = $('meta[name="description"]');
      document.title = title;
      $metaDesc.attr("content", desc);

      if (this.isWechat || this.isMqq) {
        const link = this.getShareUrl();
        pageShare(wx, { title, desc, link });
      }
    },
    share: function (shareData, flag = false) {
      this.flag = flag;
      this.shareData = shareData;
      if (this.isMobile) {
        if (this.isMqq || this.isWechat) {
          if (flag) {
            this.initShareInfo(shareData);
          }
          this.shareTips = true;
        } else {
          this.copyShareUrl();
        }
      } else {
        qrcode.toDataURL(this.getShareUrl(), { width: 190 }, (err, url) => {
          if (err) throw err;
          this.qrcodeImg = url;
        });
        this.qrcodeVisible = true;
      }
    },
    copyShareUrl() {
      const content = `【仅剩 ${this.shareData.ren} 个名额】我 ${
        this.shareData.groupPrice
      } 元拼了原价 ${this.shareData.originPrice} 元的课程《${
        this.shareData.title
      }》，点击链接参团一起享受优惠 ${this.getShareUrl()}`;
      clipboard(content, () => {
        this.tipModal(
          "",
          "拼团链接复制成功，打开微信或 QQ 可粘贴并发送给好友。",
          "好的"
        );
      });
    },
    getShareUrl() {
      let urlSearch = qs.parse(window.location.search.substring(1));
      delete urlSearch.isPaided;
      urlSearch = qs.stringify(urlSearch);
      if (this.flag) {
        return (
          process.env.VUE_APP_DOMAIN +
          "/group-activity/" +
          this.shareData.groupNo
        );
      } else {
        return `${window.location.origin}${window.location.pathname}${
          urlSearch ? "?" + urlSearch : ""
        }`;
      }
    },
    tipModal(title, msg, confirmText) {
      const h = this.$createElement;
      this.$nextTick(() => {
        let messageBox = document.querySelector(".el-message-box");
        messageBox.style.width = "100%";
      });
      this.$msgbox({
        title,
        message: h("p", null, [h("span", null, msg)]),
        center: true,
        confirmButtonText: confirmText,
      });
    },
  },
  computed: {
    isMobile() {
      return isMobile();
    },
    isWechat() {
      return isWeChat();
    },
    isMqq() {
      return isMqq();
    },
  },
};
</script>
<style lang="scss" scoped>
.group-share {
  :deep .el-dialog__header {
    display: inline-block;
  }
}
.share-tip-inWechat {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.6);

  .arrow-topRight {
    img {
      width: 150px;
      float: right;
      height: 190px;
      margin-right: 18px;
      margin-top: 35px;
      margin-bottom: 24px;
    }
  }

  .tip-text {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-bottom: 32px;
  }

  .i-know-it {
    text-align: center;

    img {
      width: 112px;
    }
  }
}
</style>
